/* eslint-disable */
import PermissionChecker from '@/security/permission-checker';

export class CategoryPermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(
      currentUser,
    );

    this.read = permissionChecker.match('viewCategories');
    this.create = permissionChecker.match('createCategory');
    this.edit = permissionChecker.match('editCategory');
    this.destroy = permissionChecker.match('deleteCategory');

    this.print = permissionChecker.match('printCategoriesTable');
    this.export = permissionChecker.match('exportCategoriesTable');
  }
}
