<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium" :class="isRTL ? 'ml-auto' : 'mr-auto'">
        <app-i18n code="category.title"></app-i18n>
      </h2>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div
        class="flex flex-col sm:flex-row sm:items-end xl:items-start justify-end"
      >
        <div class="flex sm:mt-0 gap-2 sm:flex-nowrap flex-wrap">
          <button
            v-if="hasPermissionToCreate"
            class="btn bg-theme-36 text-white sm:w-auto pb-2 pt-2 flex-1 sm:flex-initial"
            @click="$router.push('/category/new')"
          >
            <PlusIcon class="w-5 h-5 mr-2 ml-2" /> {{ i18n('common.create') }}
          </button>
          <app-action-toolbar
            @onPrint="onPrint()"
            @onExportXlsx="onExportXlsx()"
            @onDownloadPdf="onDownloadPdf()"
            :printPermission="hasPermissionToPrint"
            :exportPermission="hasPermissionToExport"
            :canExportAll="false"
          ></app-action-toolbar>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden">
        <div ref="table" class="relative">
          <loading-spinner
            type="half-circle"
            :duration="1000"
            :size="40"
            color="#4469A8"
            :loading="loading"
          />
          <div class="overflow-x-auto">
            <table
              ref="tableRef"
              id="categoryTable"
              class="table table-report sm:mt-2"
            >
              <thead>
                <tr>
                  <th class="whitespace-nowrap">#</th>
                  <th
                    v-for="col in columns"
                    :key="col.name"
                    :id="col.name === 'action' ? 'ignore-1' : ''"
                    class="text-center whitespace-nowrap"
                  >
                    {{ i18n(col.label) }}
                  </th>
                </tr>
              </thead>
              <tbody v-if="rows.length">
                <tr
                  v-for="(row, index) in rows"
                  :key="index"
                  class="intro-x cursor-move"
                  :data-index="index"
                  draggable="true"
                  @dragstart="handleDragStart"
                  @dragenter="handleDragEnter"
                  @dragleave="handleDragLeave"
                  @dragover="handleDragOver"
                  @drop="handleDrop"
                >
                  <td class="">
                    <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                      {{ rowIndex(index) }}
                    </div>
                  </td>
                  <td class="text-center flex items-center justify-center">
                    <app-list-item-image
                      :value="row.image"
                    ></app-list-item-image>
                  </td>
                  <td class="text-center">
                    <div class="flex justify-center">
                      <div class="font-medium whitespace-nowrap w-72 truncate">
                        {{ presenterLocalization(row, 'name') }}
                      </div>
                    </div>
                  </td>
                  <td class="table-report__action" id="ignore-2">
                    <div class="flex justify-center items-center gap-4">
                      <router-link
                        v-if="hasPermissionToEdit"
                        :to="`/category/edit/${row.id}`"
                        class="text-theme-10 dark:text-theme-30 font-medium flex items-center gap-1 whitespace-nowrap"
                      >
                        <Tippy
                          tag="div"
                          class="flex items-center gap-1 whitespace-nowrap"
                          :content="i18n('common.edit')"
                        >
                          <EditIcon class="w-4 h-4" /> {{ i18n('common.edit') }}
                        </Tippy>
                      </router-link>

                      <button
                        v-if="hasPermissionToDestroy"
                        type="button"
                        class="text-theme-24 font-medium"
                        @click="showModal('delete', row.id)"
                      >
                        <Tippy
                          tag="div"
                          class="flex items-center gap-1 whitespace-nowrap"
                          :content="i18n('common.delete')"
                        >
                          <Trash2Icon class="w-4 h-4" />
                          {{ i18n('common.delete') }}
                        </Tippy>
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <app-empty-page
              v-if="!rows.length && !loading"
              :label="i18n('empty.category')"
              label-class="text-lg font-medium"
              icon-size="4x"
              class="box w-full py-64"
            ></app-empty-page>
          </div>
        </div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
    <!-- BEGIN: Delete Modal -->
    <DeleteModal item="category" @delete="doDestroy(selectedId)" />
    <!-- END: Delete Modal -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import DeleteModal from '@/components/modals/delete-modal.vue'
import { CategoryPermissions } from './category-permissions'
import { presenterMixin } from '@/mixins/presenter-mixin'
import { exportPrintMixin } from '@/mixins/export-print-mixin'

export default {
  mixins: [presenterMixin, exportPrintMixin],
  components: {
    DeleteModal
  },
  data() {
    return {
      // data important for Mixins
      tableRef: null,
      exportTableName: 'categories',
      excludedCols: [1, 3],
      ignoreElements: ['ignore-1', 'ignore-2'],
      // component Data
      timer: null,
      columns: [
        {
          name: 'image',
          field: 'image',
          label: 'category.fields.image',
          align: 'center'
        },
        {
          name: 'name',
          field: 'name',
          label: 'category.fields.name',
          align: 'center'
        },
        {
          name: 'action',
          field: 'action',
          label: 'common.actions',
          align: 'center'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      currentUser: 'auth/currentUser',
      rows: 'category/list/categories',
      listLoading: 'category/list/listAllLoading',
      destroyLoading: 'category/destroy/loading'
    }),
    currentPage() {
      return this.pagination.currentPage || 1
    },
    rowIndex() {
      return index => {
        return index + 1
      }
    },
    hasPermissionToCreate() {
      return new CategoryPermissions(this.currentUser).create
    },
    hasPermissionToEdit() {
      return new CategoryPermissions(this.currentUser).edit
    },
    hasPermissionToDestroy() {
      return new CategoryPermissions(this.currentUser).destroy
    },
    hasPermissionToPrint() {
      return new CategoryPermissions(this.currentUser).print
    },
    hasPermissionToExport() {
      return new CategoryPermissions(this.currentUser).export
    },
    loading() {
      return this.listLoading || this.destroyLoading
    }
  },
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.categories')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.categories')
    }
  },
  async mounted() {
    await this.doListAll()
  },
  methods: {
    ...mapActions({
      listAllCategories: 'category/list/listAllCategories',
      doDestroy: 'category/destroy/doDestroy',
      doArrangeCategories: 'category/form/doArrangeCategories'
    }),
    async doListAll() {
      await this.listAllCategories()
    },
    i18n(key, args) {
      return this.$t(key, args)
    },
    showModal(modalName, id) {
      this.selectedId = id
      switch (modalName) {
        case 'delete':
          cash('#delete-modal-preview').modal('show')
          break
        case 'addImage':
          cash('#add-image-to-category-modal').modal('show')
          break
      }
    },
    handleDragStart(e) {
      e.dataTransfer.setData('text/plain', e.currentTarget.dataset.index)
    },
    handleDragEnter(e) {
      e.currentTarget.classList.add('hover')
    },
    handleDragLeave(e) {
      e.currentTarget.classList.remove('hover')
    },
    handleDragOver(e) {
      e.preventDefault()
    },
    handleDrop(e) {
      e.preventDefault()
      const itemIndex = e.dataTransfer.getData('text/plain')
      const droppedIndex = e.currentTarget.dataset.index
      Array.from(e.currentTarget.parentNode.children).map(tr => {
        tr.classList.remove('hover')
      })
      this.handleListChange(itemIndex, droppedIndex)
    },
    handleListChange(index, newIndex) {
      if (index == newIndex) return
      const oldIndex = parseInt(index, 10)
      const newPositionIndex = parseInt(newIndex, 10)
      const movedItem = this.rows[oldIndex]
      this.rows.splice(oldIndex, 1)
      this.rows.splice(newPositionIndex, 0, movedItem)
      this.doArrange()
    },
    async doArrange() {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        const list = this.rows.map(row => row.id)
        this.doArrangeCategories(list)
        clearTimeout(this.timer)
      }, 2000)
    }
  }
}
</script>

<style scoped>
.table-report:not(.table-report--bordered):not(.table-report--tabulator) tr {
  border-radius: 0px !important;
  box-shadow: 15px 0px 15px #0000000b;
}

.table-report:not(.table-report--bordered):not(.table-report--tabulator) td {
  border-radius: 0px !important;
  box-shadow: unset !important;
}

.pagination-ar {
  margin-right: unset !important;
  margin-left: auto !important;
}
</style>
